<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="4000" bottom app
      >{{ snackbarText }}
    </v-snackbar>
    <section>
      <v-container class="fill-height py-8" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="v-responsive" style="max-width: 700px">
              <div class="v-responsive__content">
                <div class="text-overline secondary--text mb-3">Mira One</div>
                <h2 class="text-h3 mb-6">Willkommen!</h2>
              </div>
            </div>
            <v-card max-width="600px" class="pa-4">
              <v-card-text>
                <div class="text-subtitle-1">
                  Deine Anmeldung war erfolgreich!
                  <br />
                  <br />
                  Wir haben Dir eine E-Mail gesendet. Bitte klicke auf den Link,
                  um Deine E-Mail-Adresse zu bestätigen.
                </div>
                <v-btn plain class="mt-4" @click="resendVerification()">
                  <v-icon left>mdi-email-sync</v-icon>
                  E-Mail erneut senden
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { auth } from "@/firebase";

export default {
  name: "setup-verify-email",
  data() {
    return {
      snackbar: false,
      snackbarText: "",
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // Check if email is verified
    },
    resendVerification() {
      var user = auth.currentUser;
      user
        .sendEmailVerification()
        .then(function () {
          // Email sent.
          this.snackbarText = "E-Mail erneut gesendet";
          this.snackbar = true;
        })
        .catch(function (error) {
          // An error happened.
          console.log(error);
          this.snackbarText =
            "Fehler: Versuche es später erneut oder kontaktiere den Support!";
          this.snackbar = true;
        });
    },
  },
};
</script>
