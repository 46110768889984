<!-- 

// IT-Konto
// 1: Willkommen
// 2: 

// Persönliches Konto
// 1: Willkommen
// 2: Vorname, Nachname
// 3: Kontotyp wählen – Anwender oder Administrator
// 4: App laden (iOS, Android)


-->
<template>
  <div>
    <section>
      <v-container>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            max-width="700px"
            persistent
            hide-overlay
            no-click-animation
            flat
            scrollable
            key="dialog1"
          >
            <v-card :loading="loading">
              <div>
                <v-stepper v-model="e1" style="box-shadow: 0px 0px 0px 0px">
                  <template>
                    <v-stepper v-model="e1" style="box-shadow: 0px 0px 0px 0px">
                    </v-stepper>

                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-card class="mb-12" flat>
                          <v-card-title>Willkommen bei Mira One!</v-card-title>
                          <v-card-text>
                            <p class="subtitle-1">
                              Gemeinsam richten wir in den nächsten Schritten
                              Dein persönliches Konto ein und unterstützen bei
                              den ersten Schritten mit Mira One. Dieser Prozess
                              dauert nur wenige Minuten.
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>

                    <v-stepper-items>
                      <v-stepper-content step="2">
                        <v-card class="mb-12" flat>
                          <v-card-title>Namen eingeben</v-card-title>
                          <v-card-text>
                            <p class="subtitle-1">
                              Gib Deinen Vor- und Nachnamen ein, damit wir das
                              Benutzererlebnis an Dich anpassen können.
                            </p>
                            <v-spacer style="height: 14px"></v-spacer>
                            <v-form @submit.prevent>
                              <v-text-field
                                label="Vorname"
                                required
                                outlined
                                v-model="firstName"
                              ></v-text-field>
                              <v-text-field
                                label="Nachname"
                                required
                                outlined
                                v-model="lastName"
                              ></v-text-field>
                            </v-form>
                            <p>
                              Der Vor- und Nachname wird auch anderen Personen
                              angezeigt. Die Einstellungen betreffend Deine
                              Privatssphäre kannst Du in Deinem Profil anpassen.
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>

                    <v-stepper-items>
                      <v-stepper-content step="3">
                        <v-card class="mb-12" flat>
                          <v-card-title>Profilbild</v-card-title>
                          <v-card-text>
                            <p class="subtitle-1">
                              Standardmäßig werden Deine Initialien als
                              Profilbild verwendet. Du kannst jedoch auch ein
                              eigenes Profilbild hochladen.
                            </p>
                            <v-spacer style="height: 14px"></v-spacer>
                            <v-form @submit.prevent>
                              <v-row>
                                <v-col cols="12" sm="12" md="3">
                                  <v-avatar size="82" color="grey lighten-2">
                                    <div class="title">
                                      {{ initialien(firstName, lastName) }}
                                    </div>
                                  </v-avatar>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                  <v-file-input
                                    outlined
                                    chips
                                    show-size
                                    clearable
                                    accept=".png, .jpg, .jpeg"
                                    label="Profilbild hochladen"
                                    persistent-hint
                                    hint=".jpg, .jpeg oder .png Dokument hochladen"
                                    prepend-icon="mdi-file-image-outline"
                                  >
                                  </v-file-input>
                                </v-col>
                              </v-row>
                            </v-form>
                            <p class="mt-8">
                              Das Profilbild wird auch anderen Personen
                              angezeigt. Die Einstellungen betreffend Deine
                              Privatssphäre kannst Du in Deinem Profil anpassen.
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>

                    <v-stepper-items>
                      <v-stepper-content step="4">
                        <v-card class="mb-12" flat>
                          <v-card-title
                            >Privatssphäre und Datenschutz
                          </v-card-title>
                          <v-card-text>
                            <div class="subtitle-1 mb-6">
                              Zur Verbesserung von Mira One verwenden wir Tools
                              zur anonymisierten Analyse und
                              Leistungsüberwachung, sofern Du hier einwilligst.
                              Deine Zustimmung kannst Du jederzeit widerrufen.
                            </div>
                            <v-form @submit.prevent>
                              <v-card outlined>
                                <v-card-title>
                                  <v-icon dense class="mr-4"
                                    >mdi-google-analytics</v-icon
                                  >
                                  <div class="subtitle-1 font-weight-medium">
                                    Verwendung von Google Analytics
                                  </div>
                                </v-card-title>
                                <v-card-text>
                                  <div>
                                    Zur Verbesserung unserer Produkte setzen wir
                                    Google Analytics zur Analyse ein. Dabei
                                    werden verschiedene Daten gesammelt, die
                                    helfen das Benutzerverhalten auszuwerten und
                                    so für die Optimierung der Dienste und
                                    Benutzererlebnis eingesetzt werden. Es
                                    erfolgt keine Verknüpfung mit deiner
                                    Identität oder personenbezogenen Daten.
                                    Deine IP-Adresse wird anonymisiert. Du
                                    kannst Deine Einwilligung jederzeit
                                    widerrufen.
                                  </div>
                                  <v-switch
                                    v-model="privacy.web.googleAnalytics.usage"
                                    inset
                                    dense
                                    :label="
                                      switchText(
                                        privacy.web.googleAnalytics.usage
                                      )
                                    "
                                    @change="setTimestampGA()"
                                  ></v-switch>
                                </v-card-text>
                              </v-card>
                              <v-spacer style="height: 14px"></v-spacer>
                              <v-card outlined>
                                <v-card-title>
                                  <v-icon dense class="mr-4"
                                    >mdi-gauge-empty</v-icon
                                  >
                                  <div class="subtitle-1 font-weight-medium">
                                    Verwendung von Firebase Performance
                                    Monitoring
                                  </div>
                                </v-card-title>
                                <v-card-text>
                                  <div>
                                    Zur Verbesserung unserer Produkte setzen wir
                                    Firebase Performance Monitoring zur
                                    Leistungsüberwachung ein. Dabei werden
                                    verschiedene Daten insbesondere betreffend
                                    die Stabilität, Geschwindigkeit und
                                    Netzwerkanfragen der Anwendung gesammelt,
                                    die helfen die Leistung der Anwendung
                                    auszuwerten, Leistungsprobleme zu beheben
                                    und auf diese Weise das Benutzererlebnis zu
                                    optimieren. Es erfolgt keine Verknüpfung mit
                                    deiner Identität oder personenbezogenen
                                    Daten. Es werden lediglich technische
                                    Parameter analyisiert. Du kannst Deine
                                    Einwilligung jederzeit widerrufen.
                                  </div>
                                  <v-switch
                                    v-model="
                                      privacy.web.performanceMonitoring.usage
                                    "
                                    inset
                                    dense
                                    :label="
                                      switchText(
                                        privacy.web.performanceMonitoring.usage
                                      )
                                    "
                                    @change="setTimestampPM()"
                                  ></v-switch>
                                </v-card-text>
                              </v-card>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>

                    <v-stepper-items>
                      <v-stepper-content step="5">
                        <v-card class="mb-12" flat>
                          <v-card-title>Einrichtung abgeschlossen</v-card-title>
                          <v-card-text>
                            <p class="subtitle-1">
                              Super! Du hast die Einrichtung abgeschlossen.
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>
                  </template>
                </v-stepper>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="skipBtn"
                  plain
                  :disabled="startBtn"
                  @click="skipActionHandler()"
                  class="mr-2"
                >
                  Überspringen
                </v-btn>
                <v-btn
                  :disabled="nextStepBtn"
                  :loading="loading"
                  color="primary"
                  depressed
                  @click="nextActionHandler()"
                >
                  Weiter
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue, Timestamp } from "@/firebase";

export default {
  name: "setup-onboarding",
  data() {
    return {
      e1: 1,
      steps: 6,
      dialog: true,
      loading: false,
      skipBtn: false,

      firstName: null,
      lastName: null,
      photoURL: null,
      meta: {
        accountType: "user", // 'user' oder 'service'
      },
      privacy: {
        web: {
          googleAnalytics: {
            usage: false,
            consentTimestamp: null,
          },
          performanceMonitoring: {
            usage: false,
            consentTimestamp: null,
          },
        },
      },
    };
  },
  methods: {
    switchText(item) {
      if (item) {
        return "Aktivieren";
      } else {
        return "Nicht aktivieren";
      }
    },
    setTimestampGA() {
      if (this.privacy.web.googleAnalytics.usage === true) {
        var timestamp = Date.now();
        this.privacy.web.googleAnalytics.consentTimestamp = timestamp;
      }
    },
    setTimestampPM() {
      if (this.privacy.web.performanceMonitoring.usage === true) {
        var timestamp = Date.now();
        this.privacy.web.performanceMonitoring.consentTimestamp = timestamp;
      }
    },
    initialien(firstName, lastName) {
      const fullName = firstName + " " + lastName;
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
    nextActionHandler() {
      switch (this.e1) {
        case 1: // Willkommen bei Mira One!
          this.e1 = this.e1 + 1;
          break;
        case 2: // Vor- und Nachnamen eingeben
          this.e1 = this.e1 + 2; // +1 bei Profilbild
          this.skipBtn = false; // true bei Profilbild
          break;
        case 3: // Profilbild
          this.e1 = this.e1 + 1;
          this.skipBtn = false;
          break;
        case 4: // Datenschutz und Privatssphäre
          // this.loading = true;
          this.setTimestampGA();
          this.setTimestampPM();
          this.finishSetup();
          this.e1 = this.e1 + 1;
          break;
        case 5: // Einrichtung abschließen
          this.loading = false;
          this.closeSetup();
          break;
        default:
          break;
      }
    },
    skipActionHandler() {
      this.e1 = this.e1 + 1;
    },
    async finishSetup() {
      var userRef = await db.collection("users").doc(auth.currentUser.uid);
      userRef
        .update({
          firstName: this.firstName,
          lastName: this.lastName,
          // photoURL: this.photoURL,
          "meta.awaitsSetup": FieldValue.delete(),
          "settings.language": "de",
          // "meta.accountType": this.meta.accountType,
          "privacy.web.googleAnalytics.usage":
            this.privacy.web.googleAnalytics.usage,
          "privacy.web.googleAnalytics.consentTimestamp": Timestamp.fromDate(
            new Date(this.privacy.web.googleAnalytics.consentTimestamp)
          ),
          "privacy.web.performanceMonitoring.usage":
            this.privacy.web.performanceMonitoring.usage,
          "privacy.web.performanceMonitoring.consentTimestamp":
            Timestamp.fromDate(
              new Date(this.privacy.web.performanceMonitoring.consentTimestamp)
            ),
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });

      var user = auth.currentUser;
      user
        .updateProfile({
          displayName: this.firstName + " " + this.lastName,
        })
        .then(function () {
          // Update successful.
          console.log("User profile successfully updated!");
          this.loading = false;
        })
        .catch(function (error) {
          // An error happened.
          console.error("Error updating user profile: ", error);
        });
    },
    closeSetup() {
      this.$router.push({ name: "organization-list" });
    },
  },
};
</script>

<style>
/* .willkommen {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
} */
</style>
